import { defineStore } from "pinia";
import { Meta, ResponseType } from "~/types/general";
import {
  Attribute,
  EditingAttribute,
  EditingAttributeValue,
  AttributeTypes,
  AttributeValue,
  UnitOptions,
  Unit,
} from "~/types/attributes";

export const useAttributesStore = defineStore("attributes", () => {
  // State
  const isLoading = ref(false);
  const attributes = ref<Array<Attribute>>([]);
  const meta = ref<Meta>({});
  const page = ref(1);
  const perPage = ref(40);
  const totalPages = ref(null);
  const isOpenSidebar = ref(false);
  const editingAttribute = ref<EditingAttribute>({} as EditingAttribute);
  const viewingAttribute = ref<Attribute>({} as Attribute);
  const attributeTypes = ref([
    { name: "Regular", value: AttributeTypes.TEXT },
    { name: "Measure", value: AttributeTypes.MEASURE },
    { name: "Rights", value: AttributeTypes.RIGHTS },
  ]);
  const editingAttributeValue = ref<EditingAttributeValue>(
    {} as EditingAttributeValue
  );
  const viewingAttributeValue = ref<AttributeValue>({} as AttributeValue);
  const unitOptions = ref([
    { name: "Box", value: UnitOptions.BOX },
    { name: "Pallet", value: UnitOptions.PALLET },
    { name: "Deck", value: UnitOptions.DECK },
    { name: "Bag", value: UnitOptions.BAG },
    { name: "Crate", value: UnitOptions.CRATE },
    { name: "Tray", value: UnitOptions.TRAY },
    { name: "Stillage", value: UnitOptions.STILLAGE },
    { name: "Unit", value: UnitOptions.UNIT },
  ]);

  // Getters
  // Add any getters here

  // Actions

  // Get Attributes
  async function getAttributes(payload?): Promise<ResponseType<Attribute[]>> {
    let response: any;

    try {
      response = await useVaniloApi("/properties", {
        params: {
          page: page.value,
          limit: perPage.value,
          ...(payload && payload),
        },
      });
      attributes.value =
        response.meta.current_page === 1
          ? response?.data
          : [...attributes.value, ...response?.data];

      page.value = response.meta.current_page;
      totalPages.value = response.meta.last_page;
      meta.value = response.meta;
    } catch (error) {
      useErrorNotifications(error);
    } finally {
      isLoading.value = false;
    }

    return response;
  }

  // Get Attribute
  async function getAttribute(id): Promise<ResponseType<Attribute[]>> {
    let response: any;
    try {
      response = await useVaniloApi(`/properties/${id}`);
    } catch (error) {
      useErrorNotifications(error);
    }

    viewingAttribute.value = response?.data;

    const { configuration, values, ...other } = response?.data;

    editingAttribute.value = {
      ...other,
      configuration: {
        show_online: configuration?.show_online ?? false,
        show_display_icon: configuration?.show_display_icon ?? false,
        is_swatch: configuration?.is_swatch ?? false,
      },
      values: values?.length ? values : [],
    };

    return response;
  }

  // Create Attribute
  async function createAttribute(): Promise<ResponseType<Attribute>> {
    let response: any;
    let requests = [];
    let valueImages = [];

    editingAttribute.value.values?.forEach((value) => {
      if (value.hasOwnProperty("image")) {
        valueImages.push({
          value: value.value,
          image: value.image,
        });
        delete value.image;
        delete value.id;
      }
    });

    const { values, ...other } = editingAttribute.value;

    const attributeValues = values?.map((i) => {
      const { settings, ...other } = i;
      const {
        units,
        reporting,
        minimumSell,
        increments,
        transportUnits,
        symbol,
        ...otherSettings
      } = settings;
      return {
        ...other,
        settings: {
          ...otherSettings,
          ...(editingAttribute.value.type === AttributeTypes.MEASURE &&
            units?.length && {
              units: units,
              reporting: reporting,
              minimumSell: minimumSell,
              increments: increments,
              transportUnits: transportUnits ?? [],
            }),
          ...(editingAttribute.value.configuration.show_display_icon &&
            editingAttribute.value.type === AttributeTypes.RIGHTS && {
              symbol: symbol ?? [],
            }),
        },
      };
    });

    let body = {
      ...other,
      values: attributeValues,
    };

    try {
      isLoading.value = true;
      response = await useVaniloApi("/properties", {
        method: "POST",
        body: body,
      });
      if (
        editingAttribute.value.hasOwnProperty("image") &&
        !useIsEmpty(editingAttribute.value.image)
      ) {
        await uploadMediaForAttribute(
          editingAttribute.value.image,
          response?.data.id
        );
      }

      response?.data?.values?.forEach((value) => {
        let uploadImage = valueImages.find(
          (item) => item.value === value.value
        );
        if (uploadImage && uploadImage.image.media) {
          let formData = new FormData();
          formData.append("media", uploadImage.image.media);
          requests.push(
            useVaniloApi(`/properties/value/${value.id}/image`, {
              method: "POST",
              body: formData,
            })
          );
        }
      });
      await Promise.all(requests);
    } catch (error) {
      useErrorNotifications(error);
    } finally {
      isLoading.value = false;
    }

    return response;
  }

  // Update Attribute
  async function updateAttribute(): Promise<ResponseType<Attribute>> {
    let response: any;
    let requests = [];
    let valueImages = [];

    editingAttribute.value.values?.forEach((value) => {
      if (value.hasOwnProperty("image")) {
        valueImages.push({
          value: value.value,
          image: value.image,
        });
        delete value.image;
      }
      delete value.media;
    });

    try {
      response = await useVaniloApi(
        `/properties/${editingAttribute.value.id}`,
        {
          method: "POST",
          body: editingAttribute.value,
        }
      );
      if (
        editingAttribute.value.hasOwnProperty("image") &&
        !useIsEmpty(editingAttribute.value.image)
      ) {
        await uploadMediaForAttribute(
          editingAttribute.value.image,
          response?.data.id
        );
      }
      response?.data.values.forEach((value) => {
        let uploadImage = valueImages.find(
          (item) => item.value === value.value
        );
        if (uploadImage && uploadImage.image.media) {
          let formData = new FormData();
          formData.append("media", uploadImage.image.media);
          requests.push(
            useVaniloApi(`/properties/value/${value.id}/image`, {
              method: "POST",
              body: formData,
            })
          );
        }
      });
      await Promise.all(requests);
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  // Delete Attribute
  async function deleteAttribute(id): Promise<ResponseType<any[]>> {
    let response: any;

    try {
      response = await useVaniloApi(`/properties/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  // Upload Media Attribute
  async function uploadMediaForAttribute(data?, id?) {
    let response: any;
    if (useIsEmpty(data)) {
      return;
    }

    let formData = new FormData();
    formData.append("media", data.media);

    try {
      response = await useVaniloApi(`/properties/${id}/image`, {
        method: "POST",
        body: formData,
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }

    return response;
  }

  return {
    isLoading,
    attributes,
    meta,
    page,
    perPage,
    totalPages,
    isOpenSidebar,
    editingAttribute,
    viewingAttribute,
    attributeTypes,
    editingAttributeValue,
    viewingAttributeValue,
    unitOptions,
    getAttributes,
    getAttribute,
    createAttribute,
    updateAttribute,
    deleteAttribute,
    uploadMediaForAttribute,
  };
});
