import { Image, Media, SidebarPrefix } from "~/types/general";

export interface Attribute {
  id?: number;
  type: string;
  name: string;
  slug?: string;
  description?: string;
  configuration: any[];
  values_count?: number;
  values: AttributeValue[];
  image?: Image;
  media?: Media;
}

export interface EditingAttribute {
  id?: number;
  type: string;
  name: string;
  slug?: string;
  description?: string;
  configuration: AttributeConfiguration;
  values_count?: number;
  values: EditingAttributeValue[];
  image?: Image;
  media?: Media;
}

export interface EditingAttributeValue {
  id?: number;
  title: string;
  value: string;
  settings: ValueSettings;
  image?: Image;
  media?: Media;
  is_symbol?: boolean;
  symbol_html?: string;
}

export enum AttributeTypes {
  TEXT = "text",
  MEASURE = "measure",
  RIGHTS = "rights",
}

export interface AttributeValue {
  id?: number;
  title: string;
  value: string;
  settings: ValueSettings[];
  image?: Image;
  media?: Media;
  is_symbol?: boolean;
  symbol_html?: string;
}

export interface AttributeConfiguration {
  show_online?: boolean;
  show_display_icon?: boolean;
  is_swatch?: boolean;
}

export interface ValueSettings {
  units?: Unit[];
  transportUnits?: string[];
  minimumSell: Unit;
  increments: Unit;
  reporting: Unit;
  hide_in_preparation?: boolean;
  hide_in_order_qty_report?: boolean;
  hide_in_product_qty_report?: boolean;
  symbol?: string[];
}

export interface Unit {
  type: string;
  ratio: number;
}

export enum UnitOptions {
  BOX = "box",
  PALLET = "pallet",
  DECK = "deck",
  BAG = "bag",
  CRATE = "crate",
  TRAY = "tray",
  STILLAGE = "stillage",
  UNIT = "unit",
}

// SIDEBAR
export const SidebarPage = {
  ATTRIBUTES_ATTRIBUTE: `${SidebarPrefix.ATTRIBUTES}-attribute`,
  ATTRIBUTES_ATTRIBUTE_VALUE: `${SidebarPrefix.ATTRIBUTES}-value`,
};
